import React from "react";

import { db } from "../firebaseConfig/db";

import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc } from "@firebase/firestore";

const Mostrarcarton = ({ numeros, email, id }) => {
  const query = collection(db, `cartones/${email}/miscartones`);
  const [docs, loading, error] = useCollectionData(query);
  //console.log(numeros);
  return (
    <table id="bingo-table">
      <tr>
        <th>
          <span class="encabezado1">B</span>
        </th>
        <th>
          <span class="encabezado1">I</span>
        </th>
        <th>
          <span class="encabezado1">N</span>
        </th>
        <th>
          <span class="encabezado1">G</span>
        </th>
        <th>
          <span class="encabezado1">O</span>
        </th>
      </tr>
      <tr>
        <td class="">{numeros.b1}</td>
        <td class="">{numeros.i1}</td>
        <td class="">{numeros.n1}</td>
        <td class="">{numeros.g1}</td>
        <td class="">{numeros.o1}</td>
      </tr>
      <tr>
        <td class="">{numeros.b2}</td>
        <td class="">{numeros.i2}</td>
        <td class="">{numeros.n2}</td>
        <td class="">{numeros.g2}</td>
        <td class="">{numeros.o2}</td>
      </tr>
      <tr>
        <td class="">{numeros.b3}</td>
        <td class="">{numeros.i3}</td>
        <td class="marcador3 ">
          <svg
            class="w-6 h-6 text-yellow-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
          </svg>
        </td>
        <td class="">{numeros.g3}</td>
        <td class="">{numeros.o3}</td>
      </tr>
      <tr>
        <td class="">{numeros.b4}</td>
        <td class="">{numeros.i4}</td>
        <td class="">{numeros.n4}</td>
        <td class="">{numeros.g4}</td>
        <td class="">{numeros.o4}</td>
      </tr>
      <tr>
        <td class="">{numeros.b5}</td>
        <td class="">{numeros.i5}</td>
        <td class="">{numeros.n5}</td>
        <td class="">{numeros.g5}</td>
        <td class="">{numeros.o5}</td>
      </tr>
    </table>
  );
};

export default Mostrarcarton;

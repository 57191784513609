import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import maracaibo from "../img/maracaibo.png";
import figura from "../img/figuras/cuadrado.png";
import trofeow from "../img/trofeow.png";
import fotoreloj from "../img/reloj.gif";
import useSound from "use-sound";

import { Link } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import menuopen from "../sound/menu-open.mp3";
import celebration from "../sound/celebration.mp3";
import bb1 from "../sound/bolitas/b1.mp3";
import bb2 from "../sound/bolitas/b2.mp3";
import bb3 from "../sound/bolitas/b3.mp3";
import bb4 from "../sound/bolitas/b4.mp3";
import bb5 from "../sound/bolitas/b5.mp3";
import bb6 from "../sound/bolitas/b6.mp3";
import bb7 from "../sound/bolitas/b7.mp3";
import bb8 from "../sound/bolitas/b8.mp3";
import bb9 from "../sound/bolitas/b9.mp3";
import bb10 from "../sound/bolitas/b10.mp3";
import bb11 from "../sound/bolitas/b11.mp3";
import bb12 from "../sound/bolitas/b12.mp3";
import bb13 from "../sound/bolitas/b13.mp3";
import bb14 from "../sound/bolitas/b14.mp3";
import bb15 from "../sound/bolitas/b15.mp3";

import ii16 from "../sound/bolitas/i16.mp3";
import ii17 from "../sound/bolitas/i17.mp3";
import ii18 from "../sound/bolitas/i18.mp3";
import ii19 from "../sound/bolitas/i19.mp3";
import ii20 from "../sound/bolitas/i20.mp3";
import ii21 from "../sound/bolitas/i21.mp3";
import ii22 from "../sound/bolitas/i22.mp3";
import ii23 from "../sound/bolitas/i23.mp3";
import ii24 from "../sound/bolitas/i24.mp3";
import ii25 from "../sound/bolitas/i25.mp3";
import ii26 from "../sound/bolitas/i26.mp3";
import ii27 from "../sound/bolitas/i27.mp3";
import ii28 from "../sound/bolitas/i28.mp3";
import ii29 from "../sound/bolitas/i29.mp3";
import ii30 from "../sound/bolitas/i30.mp3";

import nn31 from "../sound/bolitas/n31.mp3";
import nn32 from "../sound/bolitas/n32.mp3";
import nn33 from "../sound/bolitas/n33.mp3";
import nn34 from "../sound/bolitas/n34.mp3";
import nn35 from "../sound/bolitas/n35.mp3";
import nn36 from "../sound/bolitas/n36.mp3";
import nn37 from "../sound/bolitas/n37.mp3";
import nn38 from "../sound/bolitas/n38.mp3";
import nn39 from "../sound/bolitas/n39.mp3";
import nn40 from "../sound/bolitas/n40.mp3";
import nn41 from "../sound/bolitas/n41.mp3";
import nn42 from "../sound/bolitas/n42.mp3";
import nn43 from "../sound/bolitas/n43.mp3";
import nn44 from "../sound/bolitas/n44.mp3";
import nn45 from "../sound/bolitas/n45.mp3";

import gg46 from "../sound/bolitas/g46.mp3";
import gg47 from "../sound/bolitas/g47.mp3";
import gg48 from "../sound/bolitas/g48.mp3";
import gg49 from "../sound/bolitas/g49.mp3";
import gg50 from "../sound/bolitas/g50.mp3";
import gg51 from "../sound/bolitas/g51.mp3";
import gg52 from "../sound/bolitas/g52.mp3";
import gg53 from "../sound/bolitas/g53.mp3";
import gg54 from "../sound/bolitas/g54.mp3";
import gg55 from "../sound/bolitas/g55.mp3";
import gg56 from "../sound/bolitas/g56.mp3";
import gg57 from "../sound/bolitas/g57.mp3";
import gg58 from "../sound/bolitas/g58.mp3";
import gg59 from "../sound/bolitas/g59.mp3";
import gg60 from "../sound/bolitas/g60.mp3";

import oo61 from "../sound/bolitas/o61.mp3";
import oo62 from "../sound/bolitas/o62.mp3";
import oo63 from "../sound/bolitas/o63.mp3";
import oo64 from "../sound/bolitas/o64.mp3";
import oo65 from "../sound/bolitas/o65.mp3";
import oo66 from "../sound/bolitas/o66.mp3";
import oo67 from "../sound/bolitas/o67.mp3";
import oo68 from "../sound/bolitas/o68.mp3";
import oo69 from "../sound/bolitas/o69.mp3";
import oo70 from "../sound/bolitas/o70.mp3";
import oo71 from "../sound/bolitas/o71.mp3";
import oo72 from "../sound/bolitas/o72.mp3";
import oo73 from "../sound/bolitas/o73.mp3";
import oo74 from "../sound/bolitas/o74.mp3";
import oo75 from "../sound/bolitas/o75.mp3";

import bingoo from "../sound/bolitas/bingo.mp3";

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
} from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc, getDocs, deleteDoc } from "@firebase/firestore";

import Confetti from "react-confetti";
import Micarton from "../componentes/micarton";
import Cartonfinal from "../componentes/cartonfinal";

import io from "socket.io-client";
const socket = io(process.env.REACT_APP_SOCKET);

var todoslosc = [];
var cartonestodos = [];

const Juego = () => {
  const { width, height } = "100%";

  const [activar, SetActivar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [principal] = useSound(menuopen);
  const [sonidofinal] = useSound(celebration);
  const [bingo] = useSound(bingoo);

  const [b1] = useSound(bb1);
  const [b2] = useSound(bb2);
  const [b3] = useSound(bb3);
  const [b4] = useSound(bb4);
  const [b5] = useSound(bb5);
  const [b6] = useSound(bb6);
  const [b7] = useSound(bb7);
  const [b8] = useSound(bb8);
  const [b9] = useSound(bb9);
  const [b10] = useSound(bb10);
  const [b11] = useSound(bb11);
  const [b12] = useSound(bb12);
  const [b13] = useSound(bb13);
  const [b14] = useSound(bb14);
  const [b15] = useSound(bb15);

  const [i16] = useSound(ii16);
  const [i17] = useSound(ii17);
  const [i18] = useSound(ii18);
  const [i19] = useSound(ii19);
  const [i20] = useSound(ii20);
  const [i21] = useSound(ii21);
  const [i22] = useSound(ii22);
  const [i23] = useSound(ii23);
  const [i24] = useSound(ii24);
  const [i25] = useSound(ii25);
  const [i26] = useSound(ii26);
  const [i27] = useSound(ii27);
  const [i28] = useSound(ii28);
  const [i29] = useSound(ii29);
  const [i30] = useSound(ii30);

  const [n31] = useSound(nn31);
  const [n32] = useSound(nn32);
  const [n33] = useSound(nn33);
  const [n34] = useSound(nn34);
  const [n35] = useSound(nn35);
  const [n36] = useSound(nn36);
  const [n37] = useSound(nn37);
  const [n38] = useSound(nn38);
  const [n39] = useSound(nn39);
  const [n40] = useSound(nn40);
  const [n41] = useSound(nn41);
  const [n42] = useSound(nn42);
  const [n43] = useSound(nn43);
  const [n44] = useSound(nn44);
  const [n45] = useSound(nn45);

  const [g46] = useSound(gg46);
  const [g47] = useSound(gg47);
  const [g48] = useSound(gg48);
  const [g49] = useSound(gg49);
  const [g50] = useSound(gg50);
  const [g51] = useSound(gg51);
  const [g52] = useSound(gg52);
  const [g53] = useSound(gg53);
  const [g54] = useSound(gg54);
  const [g55] = useSound(gg55);
  const [g56] = useSound(gg56);
  const [g57] = useSound(gg57);
  const [g58] = useSound(gg58);
  const [g59] = useSound(gg59);
  const [g60] = useSound(gg60);

  const [o61] = useSound(oo61);
  const [o62] = useSound(oo62);
  const [o63] = useSound(oo63);
  const [o64] = useSound(oo64);
  const [o65] = useSound(oo65);
  const [o66] = useSound(oo66);
  const [o67] = useSound(oo67);
  const [o68] = useSound(oo68);
  const [o69] = useSound(oo69);
  const [o70] = useSound(oo70);
  const [o71] = useSound(oo71);
  const [o72] = useSound(oo72);
  const [o73] = useSound(oo73);
  const [o74] = useSound(oo74);
  const [o75] = useSound(oo75);

  //SELECCION DE NUMEROS
  const options = [];
  for (let i = 1; i <= 75; i++) {
    options.push({
      label: i,
      value: i,
    });
  }

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const mensaje = (value, tipo, idcarton) => {
    if (value == "Felicidades") {
      msjsave(value, tipo);
      SetActivar(true);
      ganar(idcarton);
    }
    if (tipo == "error") {
      msjsave(value, tipo);
    }
  };

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };

  const query = collection(db, `cartones/${email}/miscartones`);
  const [docs, loading, error] = useCollectionData(query);

  const [documents, setDocuments] = useState([]);

  const [bolita, setBolita] = useState(0);
  const [sacada, setSacada] = useState([]);
  const [bolitas, setBolitas] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
  ]);

  const [figuras, setFiguras] = useState([]);

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  const [ganadores, setGanadores] = useState([]);
  const [cartonesganadores, setCartonesganadores] = useState([]);
  const [finalizo, setFinalizo] = useState(false);
  const [cierre, setCierre] = useState(false);
  const [premio, setPremio] = useState(0);
  const [premioinicial, setPremioinicial] = useState(0);
  const [namefigura, SetNamefigura] = useState("");
  const [figuraurl, setFiguraurl] = useState("");
  const [tiempo, setTiempo] = useState("");
  const [iniciando, setIniciando] = useState(true);
  const [salir, setSalir] = useState(false);
  const [emaillocal, setEmaillocal] = useState("user@example.com");

  const [emailf, setEmailf] = useState("");

  const [min, setMin] = useState(0);
  const [seg, setSeg] = useState(0);

  const [users, setUsers] = useState([]);
  const [juego, setJuego] = useState([]);
  const [cartonesusers, setCartonesusers] = useState([]);
  const [cartonesusers2, setCartonesusers2] = useState([]);

  const gettodosloscartones = () => {
    todoslosc = [];
    for (let i = 0; i < users.length; i++) {
      obtenermiscartones2(users[i].email, users[i].photo, users[i].nombre);
    }
    //console.log(todoslosc);
    setCartonesusers2(todoslosc);
  };

  const obtenermiscartones2 = (correo, photo, nombre) => {
    const collectionRef = collection(db, `cartones/${correo}/miscartones`);
    getDocs(collectionRef).then((querySnapshot) => {
      const documentsArray = [];
      querySnapshot.forEach((doc) => {
        documentsArray.push({
          email: correo,
          photo: photo,
          nombre: nombre,
          id: doc.id,
          data: doc.data(),
        });
        //console.log(documentsArray);
      });
      todoslosc.push(documentsArray);
      //console.log(todoslosc);
    });
  };

  const obtenerjuego = (tipo) => {
    for (let i = 0; i < juego.length; i++) {
      if (juego[i].id == "juego") {
        if (tipo == "premio") {
          setPremioinicial(juego[i].premio);
        }
        if (tipo == "figura") {
          SetNamefigura(juego[i].figura);
        }
        if (tipo == "figuraurl") {
          setFiguraurl(juego[i].figuraurl);
        }
      }
    }
  };

  const obtenermiscartones = () => {
    const collectionRef = collection(db, `cartones/${email}/miscartones`);
    getDocs(collectionRef).then((querySnapshot) => {
      const documentsArray = [];
      querySnapshot.forEach((doc) => {
        documentsArray.push({
          id: doc.id,
          data: doc.data(),
        });
        //console.log(documentsArray);
      });
      setDocuments(documentsArray);
    });
  };

  const obtenermiscartones3 = () => {
    const collectionRef = collection(db, `cartones/${emailf}/miscartones`);
    getDocs(collectionRef).then((querySnapshot) => {
      const documentsArray = [];
      querySnapshot.forEach((doc) => {
        documentsArray.push({
          id: doc.id,
          data: doc.data(),
        });
        //console.log(documentsArray);
      });
      setDocuments(documentsArray);
    });
  };

  const getusers = async () => {
    const protemploCollection = collection(db, "cartones");
    const data = await getDocs(protemploCollection);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const getjuego = async () => {
    const protemploCollection = collection(db, "juegos");
    const data = await getDocs(protemploCollection);
    setJuego(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const chequearexistencia = () => {
    var existe = false;
    for (let i = 0; i < users.length; i++) {
      if (users[0].email == email) {
        existe = true;
      }
    }

    if (existe == false) {
      registraruser();
    }
  };

  const chequearexistencia2 = () => {
    var existe = false;

    for (let i = 0; i < users.length; i++) {
      if (users[i].email == emailf) {
        existe = true;
      }
    }
    //console.log(existe);
    if (existe == true) {
      obtenermiscartones3();
    }
  };

  const registraruser = async () => {
    if (email != undefined) {
      const datos = {
        email: email,
        nombre: displayName,
        photo: photoURL,
      };
      const protemploCollection = collection(db, "cartones");
      const userId = email;
      await setDoc(doc(protemploCollection, userId), datos);
    }
  };

  const obtenernombre = (correo, tipo) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email == correo) {
        let nombre = users[i].nombre;
        var PrimerN = "";
        for (let a = 0; a < nombre.length; a++) {
          if (nombre[a] === " ") {
            a = nombre.length;
          } else {
            PrimerN += nombre[a];
          }
        }
        if (tipo == "nombre") {
          return PrimerN;
        }
        if (tipo == "foto") {
          return users[i].photo;
        }
      }
    }
  };

  const revisarbolita = (bola) => {
    if (bola === 1) {
      b1();
    }
    if (bola === 2) {
      b2();
    }
    if (bola === 3) {
      b3();
    }
    if (bola === 4) {
      b4();
    }
    if (bola === 5) {
      b5();
    }
    if (bola === 6) {
      b6();
    }
    if (bola === 7) {
      b7();
    }
    if (bola === 8) {
      b8();
    }
    if (bola === 9) {
      b9();
    }
    if (bola === 10) {
      b10();
    }
    if (bola === 11) {
      b11();
    }
    if (bola === 12) {
      b12();
    }
    if (bola === 13) {
      b13();
    }
    if (bola === 14) {
      b14();
    }
    if (bola === 15) {
      b15();
    }
    if (bola === 16) {
      i16();
    }
    if (bola === 17) {
      i17();
    }
    if (bola === 18) {
      i18();
    }
    if (bola === 19) {
      i19();
    }
    if (bola === 20) {
      i20();
    }
    if (bola === 21) {
      i21();
    }
    if (bola === 22) {
      i22();
    }
    if (bola === 23) {
      i23();
    }
    if (bola === 24) {
      i24();
    }
    if (bola === 25) {
      i25();
    }
    if (bola === 26) {
      i26();
    }
    if (bola === 27) {
      i27();
    }
    if (bola === 28) {
      i28();
    }
    if (bola === 29) {
      i29();
    }
    if (bola === 30) {
      i30();
    }
    if (bola === 31) {
      n31();
    }
    if (bola === 32) {
      n32();
    }
    if (bola === 33) {
      n33();
    }
    if (bola === 34) {
      n34();
    }
    if (bola === 35) {
      n35();
    }
    if (bola === 36) {
      n36();
    }
    if (bola === 37) {
      n37();
    }
    if (bola === 38) {
      n38();
    }
    if (bola === 39) {
      n39();
    }
    if (bola === 40) {
      n40();
    }
    if (bola === 41) {
      n41();
    }
    if (bola === 42) {
      n42();
    }
    if (bola === 43) {
      n43();
    }
    if (bola === 44) {
      n44();
    }
    if (bola === 45) {
      n45();
    }
    if (bola === 46) {
      g46();
    }
    if (bola === 47) {
      g47();
    }
    if (bola === 48) {
      g48();
    }
    if (bola === 49) {
      g49();
    }
    if (bola === 50) {
      g50();
    }
    if (bola === 51) {
      g51();
    }
    if (bola === 52) {
      g52();
    }
    if (bola === 53) {
      g53();
    }
    if (bola === 54) {
      g54();
    }
    if (bola === 55) {
      g55();
    }
    if (bola === 56) {
      g56();
    }
    if (bola === 57) {
      g57();
    }
    if (bola === 58) {
      g58();
    }
    if (bola === 59) {
      g59();
    }
    if (bola === 60) {
      g60();
    }
    if (bola === 61) {
      o61();
    }
    if (bola === 62) {
      o62();
    }
    if (bola === 63) {
      o63();
    }
    if (bola === 64) {
      o64();
    }
    if (bola === 65) {
      o65();
    }
    if (bola === 66) {
      o66();
    }
    if (bola === 67) {
      o67();
    }
    if (bola === 68) {
      o68();
    }
    if (bola === 69) {
      o69();
    }
    if (bola === 70) {
      o70();
    }
    if (bola === 71) {
      o71();
    }
    if (bola === 72) {
      o72();
    }
    if (bola === 73) {
      o73();
    }
    if (bola === 74) {
      o74();
    }
    if (bola === 75) {
      o75();
    }

    //console.log(bola);
  };

  const chequearlocal = () => {
    if (localStorage.getItem("emaillocal") !== null) {
      setEmaillocal(localStorage.getItem("emaillocal").toLowerCase());
    } else {
      localStorage.setItem("emaillocal", "");
    }
  };

  const obtenerlocal = () => {
    if (localStorage.getItem("emaillocal") !== null) {
      return localStorage.getItem("emaillocal").toLowerCase();
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (email === undefined) {
      obtenermiscartones();
      getusers();
      getjuego();
      chequearexistencia();
      setShowModal2(true);
    } else {
      obtenermiscartones();
      getusers();
      getjuego();
      chequearexistencia();
      setShowModal2(false);
    }
    chequearlocal();
  }, [email]);

  useEffect(() => {
    //console.log(emailf);
    chequearexistencia2(emailf);
  }, [emailf]);

  useEffect(() => {
    socket.on("message", receiveMessage);

    return () => {
      socket.off("message", receiveMessage);
    };
  }, []);

  const receiveMessage = (message) => setMessages((state) => [message]);

  useEffect(() => {
    if (finalizo == false) {
      if (messages.length > 0) {
        if (messages[0].body[0] != "Empezando") {
          //console.log(messages);
          if (messages[0].body[6] === "juego") {
            setBolita(messages[0].body[0]);
            setSacada(messages[0].body[1]);
            setBolitas(messages[0].body[2]);
            setFiguras(messages[0].body[3]);
            setIniciando(false);
            //principal();
            revisarbolita(messages[0].body[0]);
          }
        }
      }
    }
    if (messages.length > 0) {
      if (messages[0].body[4] == "Ganador") {
        setGanadores(messages[0].body[5]);
        setCartonesganadores(messages[0].body[6]);

        setFinalizo(true);
        bingo();
      }
    }
    if (messages.length > 0) {
      if (messages[0].body[4] == "Finalizado") {
        setCartonesusers2(JSON.parse(messages[0].body[7]));
        //console.log(JSON.parse(messages[0].body[7]));
        setShowModal(true);
        setCierre(true);
        setIniciando(false);
      }
    }

    if (messages.length > 0) {
      if (messages[0].body[0] == "Empezando") {
        //console.log("ESTAMOS EMPEZANDO");
        if (messages[0].body[2] === "juego") {
          setFinalizo(false);
          setCierre(false);
          SetActivar(false);
          setGanadores([]);
          setCartonesganadores([]);
          setShowModal(false);
          setBolita(0);
          setSacada([]);
          setTiempo(messages[0].body[1]);
          SetNamefigura(messages[0].body[3]);
          setFiguraurl(messages[0].body[4]);

          setIniciando(true);
        }
      }
    }
    //console.log(messages);
    if (premioinicial == 0) {
      obtenerjuego("premio");
    }
    if (namefigura == "") {
      obtenerjuego("figura");
    }
    if (figuraurl == "") {
      obtenerjuego("figuraurl");
    }
  }, [messages]);

  const ganar = (idcarton) => {
    var ganador = [];
    var cartonganador = [];
    for (let i = 0; i < ganadores.length; i++) {
      ganador.push(ganadores[i]);
    }
    for (let i = 0; i < cartonesganadores.length; i++) {
      cartonganador.push(cartonesganadores[i]);
    }

    if (!cartonganador.includes(idcarton)) {
      if (email == undefined) {
        ganador.push(emailf);
      } else {
        ganador.push(email);
      }

      cartonganador.push(idcarton);
      setGanadores(ganador);
      setCartonesganadores(cartonganador);
      const newMessage = {
        body: [
          bolita,
          sacada,
          bolitas,
          figuras,
          "Ganador",
          ganador,
          cartonganador,
        ],
        from: "Administrador",
      };
      setFinalizo(true);
      //setShowModal(true);
      sonidofinal();
      socket.emit("message", newMessage.body);
    } else {
      msjsave("El Carton ya Gano, No intentes hacer trampa", "error");
    }
  };

  const todosloscartones = () => {
    todoslosc = [];
    for (let i = 0; i < documents.length; i++) {
      //console.log(documents[i].id);
      const collectionRef = collection(
        db,
        `cartones/${documents[i].id}/miscartones`
      );
      getDocs(collectionRef).then((querySnapshot) => {
        const arraydoc = [];
        querySnapshot.forEach((doc) => {
          arraydoc.push({ id: doc.id });
        });

        todoslosc.push({ id: documents[i].id, cartones: arraydoc });
      });
    }
    //console.log(todoslosc);
    setCartonesusers(todoslosc);
  };

  useEffect(() => {
    if (ganadores.length == 0) {
    } else {
      let premionuevo = parseFloat(premioinicial) / parseInt(ganadores.length);
      setPremio(premionuevo.toFixed(2));
    }
  }, [ganadores]);

  useEffect(() => {
    if (juego.length > 0) {
      for (let i = 0; i < juego.length; i++) {
        if (juego[i].id == "juego") {
          if (juego[i].estatus == "Jugando") {
            setSacada(juego[i].resultados);
            setBolitas(juego[i].quedaron);
            setPremio(juego[i].premio);
            setPremioinicial(juego[i].premio);
            SetNamefigura(juego[i].figura);
            setFiguraurl(juego[i].figuraurl);
            setFiguras(juego[i].casillas);
            setIniciando(false);
          }
          if (juego[i].estatus == "Finalizado") {
            setSacada(juego[i].resultados);
            setBolitas(juego[i].quedaron);
            setFiguras(juego[i].casillas);
            setPremio(juego[i].premio);
            setPremioinicial(juego[i].premio);
            SetNamefigura(juego[i].figura);
            setFiguraurl(juego[i].figuraurl);
            setGanadores(juego[i].ganadores);
            setCartonesganadores(juego[i].cartonesganadores);
            setCierre(true);
            setFinalizo(true);
            setShowModal(true);
            setIniciando(false);
          }
          if (juego[i].estatus == "En Espera") {
            setFiguras(juego[i].casillas);
            setPremio(juego[i].premio);
            setPremioinicial(juego[i].premio);
            SetNamefigura(juego[i].figura);
            setFiguraurl(juego[i].figuraurl);
          }
        }
      }
    }
  }, [juego]);

  useEffect(() => {
    //console.log(users);
    //gettodosloscartones();
  }, [users]);

  useEffect(() => {
    //console.log(cartonesusers);
  }, [cartonesusers]);

  useEffect(() => {
    //console.log(cartonesusers2);
  }, [cartonesusers2]);

  useEffect(() => {
    setMin(Math.floor(parseInt(tiempo) / 60));
    setSeg(parseInt(tiempo) % 60);
  }, [tiempo]);

  useEffect(() => {
    //console.log(figuraurl);
  }, [figuraurl]);

  return (
    <>
      <div className="container w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                  <img
                    onClick={() => {
                      setSalir(true);
                    }}
                    className="w-10 h-10 cursor-pointer  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="font-medium text-white mt-[-10px]">
                    <div className="text-white text-right text-sm">
                      {displayName && <> {primerNombre} </>}
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>
      {email != undefined || emailf != "" ? (
        <>
          <div class=" flex">
            <div className="relative m-2 mt-10 bg-yellow-500 text-lg text-blue-800 font-mono pl-24 font-bold h-[50px] w-[96%] rounded-lg border-2">
              <span className=" absolute m-0 mt-6 text-sm text-cyan-700">
                Premio
              </span>
              <span className="pl-2 pr-2 absolute m-0 mt-6 ml-[55px] text-cyan-300 text-sm font-bold bg-slate-800 border-slate-500 rounded-lg">
                {premioinicial} Bs
              </span>
              SALA ESPECIAL
            </div>
            <img
              src={maracaibo}
              height="50px"
              className="m-2 mt-10 rounded-l-lg border-2 absolute h-[50px]"
            />
            <div className="m-2 items-end mt-10 rounded-l-lg border-2 absolute h-[50px]"></div>
            <img
              src={figuraurl}
              className="m-2 items-end ml-[300px] mt-5 rounded-lg border-2 absolute h-[50px]"
            />
            <span className="pl-2 pr-2 absolute m-0 mt-20 ml-[290px] text-cyan-300 text-sm font-bold bg-slate-800 border-slate-500 rounded-lg">
              {namefigura}
            </span>
          </div>
          {iniciando == true ? (
            <>
              <div className="h-[100px] rounded-xl border-2 border-blue-900 m-4 flex place-content-center bg-[#e36c4c]">
                <img src={fotoreloj} />
                {min == 0 ? (
                  <span className="mt-[32px] text-2xl text-white  font-semibold">
                    {seg} Segundos
                  </span>
                ) : (
                  <span className="mt-[32px] text-2xl text-white  font-semibold">
                    {min}:{seg} Segundos
                  </span>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {cierre == true ? (
            <div className="flex flex-wrap place-content-center m-4">
              {Object.keys(cartonesusers2).map((key) =>
                cartonesusers2[key]
                  .filter((item) => item.data.estatus !== "pendiente")
                  .map((item, index) => (
                    <Cartonfinal
                      key={`${key}${index}`}
                      id={item.id}
                      idcarton={cartonesganadores}
                      nombre={item.nombre}
                      photo={item.photo}
                      numeros={item.data}
                      sacada={sacada}
                      figuras={figuras}
                      bolitas={bolitas}
                    />
                  ))
              )}
            </div>
          ) : (
            <>
              <div className=" flex flex-grow flex-row">
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={bolita}
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                    classNames="fade"
                  >
                    {bolita > 0 && bolita < 16 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-blue-600 text-sm font-mono font-semibold">
                            B
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-blue-600 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : bolita > 15 && bolita < 31 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-amber-300 text-sm font-mono font-semibold">
                            I
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-yellow-400 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : bolita > 30 && bolita < 46 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-orange-500 text-sm font-mono font-semibold">
                            N
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-orange-500 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : bolita > 45 && bolita < 61 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-green-600 text-sm font-mono font-semibold">
                            G
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-green-600 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : bolita > 60 && bolita < 76 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute text-white rounded-lg border-1 pl-2 pr-2 bg-black text-sm font-mono font-semibold">
                            O
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-black bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </CSSTransition>
                </SwitchTransition>

                {sacada.slice(0, 6).map((val, key) => {
                  return (
                    <>
                      {key == 0 ? (
                        <></>
                      ) : sacada[key] > 0 && sacada[key] < 16 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-blue-600 text-xs text-white font-mono font-semibold">
                              B
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-blue-600  bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : sacada[key] > 15 && sacada[key] < 31 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-amber-300 text-xs font-mono font-semibold">
                              I
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-yellow-400 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : sacada[key] > 30 && sacada[key] < 46 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-orange-500 text-xs font-mono font-semibold">
                              N
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-orange-500 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : sacada[key] > 45 && sacada[key] < 61 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-green-600 text-xs font-mono font-semibold">
                              G
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-green-600 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : sacada[key] > 60 && sacada[key] < 76 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] text-white absolute rounded-lg border-1 pl-2 pr-2 bg-black text-xs font-mono font-semibold">
                              O
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-black bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="orden">
                {Object.keys(documents).map((key, index) => {
                  return (
                    <>
                      {documents[key].data.estatus !== "pendiente" ? (
                        <div key={index} className="p-4">
                          {email == undefined ? (
                            <Micarton
                              mensaje={mensaje}
                              id={index}
                              idcarton={documents[index].id}
                              email={emailf}
                              numeros={documents[index].data}
                              sacada={sacada}
                              figuras={figuras}
                              bolitas={bolitas}
                              iniciando={iniciando}
                            />
                          ) : (
                            <Micarton
                              mensaje={mensaje}
                              id={index}
                              idcarton={documents[index].id}
                              email={email}
                              numeros={documents[index].data}
                              sacada={sacada}
                              figuras={figuras}
                              bolitas={bolitas}
                              iniciando={iniciando}
                            />
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          )}

          <br />
        </>
      ) : (
        <></>
      )}

      <div class=" flex">
        {activar == true ? (
          <Confetti
            numberOfPieces={300}
            gravity={0.1}
            width={width}
            height={height}
          />
        ) : (
          <div></div>
        )}
      </div>
      <ToastContainer theme="dark" />
      {/*MODAL*/}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <img
                    className="mt-4 mb-4"
                    src={trofeow}
                    width="60px"
                    height="60px"
                  />
                  <span className="mt-6 pl-2">GANADORES</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 text-center">
                        {ganadores.map((val, key) => {
                          return (
                            <>
                              <div className="flex flex-grow place-content-between items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                <img
                                  class="w-10 h-10 rounded-full"
                                  src={obtenernombre(ganadores[key], "foto")}
                                  alt="Ganador"
                                />
                                <div class="ps-3">
                                  <div class="text-base font-semibold">
                                    {obtenernombre(ganadores[key], "nombre")}
                                  </div>
                                </div>
                                <div className="ml-10 flex flex-col">
                                  <span className=" w-24 text-xs bg-red-800 text-white rounded-t-lg">
                                    Premio
                                  </span>
                                  <span className=" w-24 font-bold bg-cyan-700 p-1 rounded-b-lg text-white">
                                    {premio} Bs
                                  </span>
                                </div>
                              </div>
                              <hr className="mt-2 p-2" />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  {cierre == true ? (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                        }}
                        data-modal-hide="popup-modal"
                        class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 "
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        &nbsp;Cerrar
                      </button>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {showModal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-10 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-md font-semibold font-mono">
                  <span className="mt-2 pl-2">Acceso a tus Cartones</span>
                </div>
                <div className="relative p-2 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 font-bold text-center">
                        Ingrese su correo
                      </div>
                      <div>
                        <input
                          type="text"
                          id="emailf"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="tu correo aqui"
                          defaultValue={obtenerlocal()}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal2(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    &nbsp;Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      var email1 = document.getElementById("emailf").value;
                      setEmailf(email1.toLowerCase());
                      localStorage.setItem("emaillocal", email1.toLowerCase());
                      setShowModal2(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-white bg-[#a51b1b] hover:bg-[#d12121]/90 focus:ring-4 focus:outline-none focus:ring-[#d12121]/50 font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                    &nbsp;Jugar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {salir ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <span className="mt-6 pl-2">Salir</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 font-bold text-center">
                        Deseas Cerrar Sesi&oacute;n?
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    &nbsp;Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleLogout();
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-white bg-[#a51b1b] hover:bg-[#d12121]/90 focus:ring-4 focus:outline-none focus:ring-[#d12121]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                    &nbsp;Salir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Juego;

import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import imgcarton from "../img/tabla.png";
import imgpremio1 from "../img/premio1.png";
import imgpremio2 from "../img/premio2.png";
import imgpremio3 from "../img/premio3.png";
import imgpremio4 from "../img/premiogordo.png";
import imgmonto from "../img/premiomonto.png";
import useSound from "use-sound";
import { Button, Drawer, Radio, Space, Popover } from "antd";
import { ConfigProvider, Flex, Popconfirm } from "antd";

import { Select } from "antd";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//IMPORTAR SONIDOS
import popoff from "../sound/pop-up-off.mp3";
import popon from "../sound/pop-up-on.mp3";
import like from "../sound/rising-pops.mp3";
import bite from "../sound/bite.mp3";
import menuopen from "../sound/menu-open.mp3";
import bienvenido from "../sound/clases/bienvenido.mp3";

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
} from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc, getDocs, deleteDoc } from "@firebase/firestore";

import Confetti from "react-confetti";
import Carton from "../componentes/carton";
import Mostrarcarton from "../componentes/mostrarcarton";

import io from "socket.io-client";
const socket = io(process.env.REACT_APP_SOCKET);

const Home = () => {
  const { width, height } = "100%";
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [activar, SetActivar] = useState(false);
  const [salir, setSalir] = useState(false);
  const [placement, setPlacement] = useState("bottom");

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  const [favoritos, setFavoritos] = useState([]);
  const [totalcartones, setTotalcartones] = useState([2]);
  const [miscartones, setMiscartones] = useState({});
  const [cartonespendientes, setCartonespendientes] = useState([]);
  const [cartonselect, setCartonselect] = useState([]);

  //SONIDOS
  const [activar1] = useSound(popon);
  const [desactivar] = useSound(popoff);
  const [megusta] = useSound(like);
  const [comenta] = useSound(bite);
  const [principal] = useSound(menuopen);

  //SELECCION DE NUMEROS
  const options = [];
  for (let i = 1; i <= 75; i++) {
    options.push({
      label: i,
      value: i,
    });
  }

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const mensaje = (value, tipo) => {
    msjsave(value, tipo);
  };

  const handleChange = (value) => {
    setMiscartones([]);
    obtenermiscartones();
    setFavoritos(value);
  };

  const handleChange2 = (value) => {
    setMiscartones([]);

    setTotalcartones(value);
  };

  const generarcartones = () => {
    var arrayb = [];
    var arrayi = [];
    var arrayn = [];
    var arrayg = [];
    var arrayo = [];

    if (favoritos.length > 8) {
      for (let i = 0; i < 8; i++) {
        if (favoritos[i] <= 15 && favoritos[i] >= 1) {
          if (arrayb.length < 5) {
            arrayb.push(favoritos[i]);
          }
        }
        if (favoritos[i] <= 30 && favoritos[i] >= 16) {
          if (arrayi.length < 5) {
            arrayi.push(favoritos[i]);
          }
        }
        if (favoritos[i] <= 45 && favoritos[i] >= 31) {
          if (arrayn.length < 5) {
            arrayn.push(favoritos[i]);
          }
        }
        if (favoritos[i] <= 60 && favoritos[i] >= 46) {
          if (arrayg.length < 5) {
            arrayg.push(favoritos[i]);
          }
        }
        if (favoritos[i] <= 75 && favoritos[i] >= 61) {
          if (arrayo.length < 5) {
            arrayo.push(favoritos[i]);
          }
        }
      }
    } else {
      if (favoritos.length <= 8) {
        for (let i = 0; i < favoritos.length; i++) {
          if (favoritos[i] <= 15 && favoritos[i] >= 1) {
            if (arrayb.length < 5) {
              arrayb.push(favoritos[i]);
            }
          }
          if (favoritos[i] <= 30 && favoritos[i] >= 16) {
            if (arrayi.length < 5) {
              arrayi.push(favoritos[i]);
            }
          }
          if (favoritos[i] <= 45 && favoritos[i] >= 31) {
            if (arrayn.length < 5) {
              arrayn.push(favoritos[i]);
            }
          }
          if (favoritos[i] <= 60 && favoritos[i] >= 46) {
            if (arrayg.length < 5) {
              arrayg.push(favoritos[i]);
            }
          }
          if (favoritos[i] <= 75 && favoritos[i] >= 61) {
            if (arrayo.length < 5) {
              arrayo.push(favoritos[i]);
            }
          }
        }
      }
    }
    var data = {};
    for (let i = 1; i <= totalcartones; i++) {
      if (i == 1) {
        //console.log(i);
        data = {
          ...data,
          carton1: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
        //console.log(data);
      }
      if (i == 2) {
        //console.log(i);
        data = {
          ...data,
          carton2: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
        //console.log(data);
      }
      if (i == 3) {
        data = {
          ...data,
          carton3: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
      }
      if (i == 4) {
        data = {
          ...data,
          carton4: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
      }
      if (i == 5) {
        data = {
          ...data,
          carton5: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
      }
      if (i == 6) {
        data = {
          ...data,
          carton6: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
      }
      if (i == 7) {
        data = {
          ...data,
          carton7: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
      }
      if (i == 8) {
        data = {
          ...data,
          carton8: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
      }
      if (i == 9) {
        data = {
          ...data,
          carton9: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
      }
      if (i == 10) {
        data = {
          ...data,
          carton10: imprimircartones(arrayb, arrayi, arrayn, arrayg, arrayo),
        };
      }
    }
    setMiscartones(data);
  };
  //

  const imprimircartones = (arrayb, arrayi, arrayn, arrayg, arrayo) => {
    var porlab = [];
    var porlai = [];
    var porlan = [];
    var porlag = [];
    var porlao = [];
    for (let i = 0; i < arrayb.length; i++) {
      porlab.push(arrayb[i]);
    }
    for (let i = 0; i < arrayi.length; i++) {
      porlai.push(arrayi[i]);
    }

    for (let i = 0; i < arrayn.length; i++) {
      porlan.push(arrayn[i]);
    }

    for (let i = 0; i < arrayg.length; i++) {
      porlag.push(arrayg[i]);
    }

    for (let i = 0; i < arrayo.length; i++) {
      porlao.push(arrayo[i]);
    }

    while (porlab.length < 5) {
      const randomNumber = generarnumeroaleatorio(1, 15);
      if (!porlab.includes(randomNumber)) {
        porlab.push(randomNumber);
      }
    }

    while (porlai.length < 5) {
      const randomNumber = generarnumeroaleatorio(16, 30);
      if (!porlai.includes(randomNumber)) {
        porlai.push(randomNumber);
      }
    }

    while (porlan.length < 4) {
      const randomNumber = generarnumeroaleatorio(31, 45);
      if (!porlan.includes(randomNumber)) {
        porlan.push(randomNumber);
      }
    }

    while (porlag.length < 5) {
      const randomNumber = generarnumeroaleatorio(46, 60);
      if (!porlag.includes(randomNumber)) {
        porlag.push(randomNumber);
      }
    }

    while (porlao.length < 5) {
      const randomNumber = generarnumeroaleatorio(61, 75);
      if (!porlao.includes(randomNumber)) {
        porlao.push(randomNumber);
      }
    }

    return [
      shuffleNumbers(porlab),
      shuffleNumbers(porlai),
      shuffleNumbers(porlan),
      shuffleNumbers(porlag),
      shuffleNumbers(porlao),
    ];
  };

  const generarnumeroaleatorio = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const showDrawer2 = () => {
    setOpen2(true);
  };
  const onClose2 = () => {
    setOpen2(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  // GENERAR CARTONES

  const shuffleNumbers = (value) => {
    const shuffledNumbers = [...value].sort(() => Math.random() - 0.5);
    return shuffledNumbers;
  };

  useEffect(() => {
    socket.on("message", receiveMessage);

    return () => {
      socket.off("message", receiveMessage);
    };
  }, []);

  const receiveMessage = (message) => setMessages((state) => [message]);

  useEffect(() => {
    if (messages.length > 0) {
      if (messages[0].body[0] === "Empezando") {
        if (messages[0].body[2] === "juego") {
          if (obtenerjuego("juego", "estatus") !== "Empezando") {
            getjuego();
          }
          var min = Math.floor(parseInt(messages[0].body[1]) / 60);
          var seg = parseInt(messages[0].body[1]) % 60;
          if (min === 0) {
            if (seg === 1) {
              setRelojjuego("Jugando");
            } else {
              setRelojjuego(seg + " Seg");
            }
          } else {
            setRelojjuego(min + ":" + seg + " Seg");
          }
        }
        if (messages[0].body[2] === "maracaibo") {
          if (obtenerjuego("maracaibo", "estatus") !== "Empezando") {
            getjuego();
          }
          var min = Math.floor(parseInt(messages[0].body[1]) / 60);
          var seg = parseInt(messages[0].body[1]) % 60;
          if (min === 0) {
            if (seg === 1) {
              setRelojmaracaibo("Jugando");
            } else {
              setRelojmaracaibo(seg + " Seg");
            }
          } else {
            setRelojmaracaibo(min + ":" + seg + " Seg");
          }
        }
        if (messages[0].body[2] === "merida") {
          if (obtenerjuego("merida", "estatus") !== "Empezando") {
            getjuego();
          }
          var min = Math.floor(parseInt(messages[0].body[1]) / 60);
          var seg = parseInt(messages[0].body[1]) % 60;
          if (min === 0) {
            if (seg === 1) {
              setRelojmerida("Jugando");
            } else {
              setRelojmerida(seg + " Seg");
            }
          } else {
            setRelojmerida(min + ":" + seg + " Seg");
          }
        }
        if (messages[0].body[2] === "caracas") {
          if (obtenerjuego("caracas", "estatus") !== "Empezando") {
            getjuego();
          }
          var min = Math.floor(parseInt(messages[0].body[1]) / 60);
          var seg = parseInt(messages[0].body[1]) % 60;
          if (min === 0) {
            if (seg === 1) {
              setRelojcaracas("Jugando");
            } else {
              setRelojcaracas(seg + " Seg");
            }
          } else {
            setRelojcaracas(min + ":" + seg + " Seg");
          }
        }
        if (messages[0].body[2] === "saltoangel") {
          if (obtenerjuego("salotangel", "estatus") !== "Empezando") {
            getjuego();
          }
          var min = Math.floor(parseInt(messages[0].body[1]) / 60);
          var seg = parseInt(messages[0].body[1]) % 60;
          if (min === 0) {
            if (seg === 1) {
              setRelojsaltoangel("Jugando");
            } else {
              setRelojsaltoangel(seg + " Seg");
            }
          } else {
            setRelojsaltoangel(min + ":" + seg + " Seg");
          }
        }

        if (messages[0].body[2] === "libre") {
          if (obtenerjuego("libre", "estatus") !== "Empezando") {
            getjuego();
          }
          var min = Math.floor(parseInt(messages[0].body[1]) / 60);
          var seg = parseInt(messages[0].body[1]) % 60;
          if (min === 0) {
            if (seg === 1) {
              setRelojlibre("Jugando");
            } else {
              setRelojlibre(seg + " Seg");
            }
          } else {
            setRelojlibre(min + ":" + seg + " Seg");
          }
        }
      }
    }

    //console.log(messages);
  }, [messages]);

  useEffect(() => {
    //console.log(miscartones);
  }, [miscartones]);
  //

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;
  //console.log(auth.user.photoURL);

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };

  const query = collection(db, `cartones/${email}/miscartones`);
  const [docs, loading, error] = useCollectionData(query);
  const [totalp, setTotalp] = useState(0);
  const [totalc, setTotalc] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState([]);
  const [carton, setCarton] = useState([]);
  const [numcarton, setNumcarton] = useState();
  const [actualizarcarton, setActualizarcarton] = useState(false);
  const [juego, setJuego] = useState([]);
  const [relojmaracaibo, setRelojmaracaibo] = useState("");
  const [relojjuego, setRelojjuego] = useState("");
  const [relojmerida, setRelojmerida] = useState("");
  const [relojcaracas, setRelojcaracas] = useState("");
  const [relojsaloangel, setRelojsaltoangel] = useState("");
  const [relojlibre, setRelojlibre] = useState("");

  const [users, setUsers] = useState([]);
  const [totales, setTotales] = useState([]);

  const [totalcomprados, setTotalcomprados] = useState(0);
  const [totalregalos, setTotalregalos] = useState(0);
  const [totalusuarios, setTotalusuarios] = useState(0);
  const [totalprecio, setTotalprecio] = useState(0);

  const totalpendiente = () => {
    var totalpe = 0;
    var cartonesp = [];
    if (docs) {
      for (let i = 0; i < docs.length; i++) {
        if (docs[i].estatus == "pendiente") {
          //console.log("HOOLLLLLL" + docs[i].estatus);
          totalpe = totalpe + 1;
        }
      }
    }
    setTotalp(totalpe);
    //console.log("PENDIENTES" + totalpe);
  };

  const totalcomprado = () => {
    var totalc = 0;
    if (docs) {
      for (let i = 0; i < docs.length; i++) {
        if (docs[i].estatus == "comprado" || docs[i].estatus == "regalo") {
          totalc = totalc + 1;
        }
      }
    }
    setTotalc(totalc);
  };

  const eliminarcarton = async (document) => {
    try {
      const docRef = doc(db, `cartones/${email}/miscartones`, document);
      await deleteDoc(docRef);
      //console.log("Documento eliminado con éxito");
      setCarton([]);
    } catch (error) {
      console.error("Error al eliminar el documento:", error);
    }
  };

  const obtenermiscartones = () => {
    const collectionRef = collection(db, `cartones/${email}/miscartones`);
    getDocs(collectionRef).then((querySnapshot) => {
      const documentsArray = [];
      querySnapshot.forEach((doc) => {
        documentsArray.push({ id: doc.id, data: doc.data() });
      });
      setDocuments(documentsArray);
      //console.log(documentsArray);
    });
  };

  const getusers = async () => {
    const protemploCollection = collection(db, "cartones");
    const data = await getDocs(protemploCollection);
    //console.log(data.docs);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const datos = async () => {
    const protemploCollection = collection(db, "precio");
    const data = await getDocs(protemploCollection);
    //console.log(data.docs);
    setTotales(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const chequearexistencia = () => {
    var existe = false;
    for (let i = 0; i < users.length; i++) {
      if (users[0].email == email) {
        existe = true;
        //console.log("SI EXISTE");
      }
    }

    if (existe == false) {
      registraruser();
    }
  };

  const registraruser = async () => {
    if (email != undefined) {
      const datos = {
        email: email,
        nombre: displayName,
        photo: photoURL,
      };
      const protemploCollection = collection(db, "cartones");
      const userId = email;
      await setDoc(doc(protemploCollection, userId), datos);
    }
  };

  const getjuego = async () => {
    const protemploCollection = collection(db, "juegos");
    const data = await getDocs(protemploCollection);
    setJuego(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const obtenerjuego = (sala, tipo) => {
    for (let i = 0; i < juego.length; i++) {
      if (juego[i].id === sala) {
        if (tipo === "premio") {
          return juego[i].premio;
        }
        if (tipo === "estatus") {
          return juego[i].estatus;
        }
      }
    }
  };

  const content = (
    <div>
      <p className=" font-semibold italic">
        {totales.length === 0 ? (
          <></>
        ) : (
          <>
            {totales[0].jugadores.map((val, key) => {
              return <div className="text-blue-700">* {val}</div>;
            })}
          </>
        )}
      </p>
    </div>
  );

  const comprar = async () => {
    console.log(email);
    console.log(displayName);
    var text =
      "%2ABingo%20Arcoiris%201.0%2A%0AHola%20soy%20%2A" +
      displayName +
      "%2A%20%2C%20y%20deseo%20comprar%20%28" +
      totalp +
      "%29%20cartones%20para%20el%20bingo.%0A%2Acorreo%3A%2A%20_" +
      email +
      "_";

    text = text.replace(/\s+/g, "%20");
    const decodeurl = decodeURIComponent(text);
    await navigator.clipboard.writeText(decodeurl);

    window.location = "https://wa.me/584121088113?text=" + text;
  };

  useEffect(() => {
    obtenermiscartones();
    getusers();
    getjuego();
    chequearexistencia();
    datos();
  }, [email]);

  useEffect(() => {}, [users]);

  useEffect(() => {
    obtenermiscartones();
  }, [totalp]);

  useEffect(() => {
    totalpendiente();

    totalcomprado();
    //console.log(documents);
  }, [handleChange]);

  useEffect(() => {
    totalpendiente();

    totalcomprado();
  }, [totalcartones]);

  useEffect(() => {
    //console.log(juego);
  }, [juego]);

  useEffect(() => {
    if (totales.length > 0) {
      setTotalcomprados(totales[0].comprados);
      setTotalregalos(totales[0].regalos);
      setTotalusuarios(totales[0].usuarios);
      setTotalprecio(totales[0].precio);
    }
  }, [totales]);

  return (
    <>
      <div className="container w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                  <img
                    onClick={() => {
                      setSalir(true);
                    }}
                    className="w-10 h-10  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />

                  <div className="font-medium text-white mt-[-10px]">
                    <div className="text-white text-right text-sm">
                      {displayName && <> {primerNombre} </>}
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>

      <br />

      <div class=" flex flex-wrap justify-center mt-8">
        {obtenerjuego("juego", "estatus") === "En Espera" ? (
          <div
            onClick={() => {
              msjsave(
                "Debes esperar la apertura de la sala, via Whatsapp",
                "warning"
              );
            }}
            className=" relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-yellow-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
            <div className=" absolute mt-[-35px]">
              <img src={imgmonto} className="relative" />
              <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                {obtenerjuego("juego", "premio")} Bs
              </span>
            </div>
            <div className="flex flex-col place-content-center">
              {/* <img src={imgpremio1} className=" rounded-tl-xl rounded-tr-xl" /> */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Fsalae.png?alt=media&token=66cae506-c0d0-4204-a7ed-b52d613263f3"
                className=" rounded-tl-xl rounded-tr-xl"
              />
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("juego", "estatus") == "Empezando" ? (
                  <>{relojjuego}</>
                ) : (
                  <>{obtenerjuego("juego", "estatus")}</>
                )}
              </span>
            </div>
          </div>
        ) : (
          <Link to={"/juego"}>
            <div className="  relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-yellow-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
              <div className=" absolute mt-[-35px]">
                <img src={imgmonto} className="relative" />
                <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                  {obtenerjuego("juego", "premio")} Bs
                </span>
              </div>
              <div className="flex flex-col place-content-center">
                {/* <img
                  src={imgpremio1}
                  className=" rounded-tl-xl rounded-tr-xl"
                /> */}
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Fsalae.png?alt=media&token=66cae506-c0d0-4204-a7ed-b52d613263f3"
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("juego", "estatus") == "Empezando" ? (
                    <>{relojjuego}</>
                  ) : (
                    <>{obtenerjuego("juego", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
          </Link>
        )}

        {obtenerjuego("maracaibo", "estatus") === "En Espera" ? (
          <div
            onClick={() => {
              msjsave(
                "Debes esperar la apertura de la sala, via Whatsapp",
                "warning"
              );
            }}
            className=" relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-yellow-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
            <div className=" absolute mt-[-35px]">
              <img src={imgmonto} className="relative" />
              <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                {obtenerjuego("maracaibo", "premio")} Bs
              </span>
            </div>
            <div className="flex flex-col place-content-center">
              <img src={imgpremio1} className=" rounded-tl-xl rounded-tr-xl" />
              {/* <img
                src="https://lotoactivo.webcindario.com/bingo/lleno.png"
                className=" rounded-tl-xl rounded-tr-xl"
              /> */}
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("maracaibo", "estatus") == "Empezando" ? (
                  <>{relojmaracaibo}</>
                ) : (
                  <>{obtenerjuego("maracaibo", "estatus")}</>
                )}
              </span>
            </div>
          </div>
        ) : (
          <Link to={"/marc"}>
            <div className=" relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-yellow-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
              <div className=" absolute mt-[-35px]">
                <img src={imgmonto} className="relative" />
                <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                  {obtenerjuego("maracaibo", "premio")} Bs
                </span>
              </div>
              <div className="flex flex-col place-content-center">
                <img
                  src={imgpremio1}
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                {/* <img
                  src="https://lotoactivo.webcindario.com/bingo/lleno.png"
                  className=" rounded-tl-xl rounded-tr-xl"
                /> */}
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("maracaibo", "estatus") == "Empezando" ? (
                    <>{relojmaracaibo}</>
                  ) : (
                    <>{obtenerjuego("maracaibo", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
          </Link>
        )}

        {obtenerjuego("merida", "estatus") == "En Espera" ? (
          <div
            onClick={() => {
              msjsave(
                "Debes esperar la apertura de la sala, via Whatsapp",
                "warning"
              );
            }}
            className=" mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-blue-800 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
            <div className=" absolute mt-[-35px]">
              <img src={imgmonto} className="relative" />
              <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                {obtenerjuego("merida", "premio")} Bs
              </span>
            </div>
            <div className="flex flex-col place-content-center">
              <img src={imgpremio2} className=" rounded-tl-xl rounded-tr-xl" />
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("merida", "estatus") == "Empezando" ? (
                  <>{relojmerida}</>
                ) : (
                  <>{obtenerjuego("merida", "estatus")}</>
                )}
              </span>
            </div>
          </div>
        ) : (
          <Link to={"/merc"}>
            <div className=" mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-blue-800 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
              <div className=" absolute mt-[-35px]">
                <img src={imgmonto} className="relative" />
                <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                  {obtenerjuego("merida", "premio")} Bs
                </span>
              </div>
              <div className="flex flex-col place-content-center">
                <img
                  src={imgpremio2}
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("merida", "estatus") == "Empezando" ? (
                    <>{relojmerida}</>
                  ) : (
                    <>{obtenerjuego("merida", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
          </Link>
        )}

        {obtenerjuego("caracas", "estatus") == "En Espera" ? (
          <div
            onClick={() => {
              msjsave(
                "Debes esperar la apertura de la sala, via Whatsapp",
                "warning"
              );
            }}
            className=" mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-red-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
            <div className=" absolute mt-[-35px]">
              <img src={imgmonto} className="relative" />
              <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                {obtenerjuego("caracas", "premio")} Bs
              </span>
            </div>
            <div className="flex flex-col place-content-center">
              <img src={imgpremio3} className=" rounded-tl-xl rounded-tr-xl" />
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("caracas", "estatus") == "Empezando" ? (
                  <>{relojcaracas}</>
                ) : (
                  <>{obtenerjuego("caracas", "estatus")}</>
                )}
              </span>
            </div>
          </div>
        ) : (
          <Link to={"/carc"}>
            <div className=" mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-red-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
              <div className=" absolute mt-[-35px]">
                <img src={imgmonto} className="relative" />
                <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                  {obtenerjuego("caracas", "premio")} Bs
                </span>
              </div>
              <div className="flex flex-col place-content-center">
                <img
                  src={imgpremio3}
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("caracas", "estatus") == "Empezando" ? (
                    <>{relojcaracas}</>
                  ) : (
                    <>{obtenerjuego("caracas", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
          </Link>
        )}

        {obtenerjuego("saltoangel", "estatus") == "En Espera" ? (
          <div
            onClick={() => {
              msjsave(
                "Debes esperar la apertura de la sala, via Whatsapp",
                "warning"
              );
            }}
            className=" mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-l-yellow-500 border-t-blue-500 border-r-red-600 border-b-green-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
            <div className=" absolute mt-[-35px]">
              <img src={imgmonto} className="relative" />
              <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                {obtenerjuego("saltoangel", "premio")} Bs
              </span>
            </div>
            <div className="flex flex-col place-content-center">
              <img src={imgpremio4} className=" rounded-tl-xl rounded-tr-xl" />
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("saltoangel", "estatus") == "Empezando" ? (
                  <>{relojsaloangel}</>
                ) : (
                  <>{obtenerjuego("saltoangel", "estatus")}</>
                )}
              </span>
            </div>
          </div>
        ) : (
          <Link to={"/salc"}>
            <div className="mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-l-yellow-500 border-t-blue-500 border-r-red-600 border-b-green-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
              <div className=" absolute mt-[-35px]">
                <img src={imgmonto} className="relative" />
                <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                  {obtenerjuego("saltoangel", "premio")} Bs
                </span>
              </div>
              <div className="flex flex-col place-content-center">
                <img
                  src={imgpremio4}
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("saltoangel", "estatus") == "Empezando" ? (
                    <>{relojsaloangel}</>
                  ) : (
                    <>{obtenerjuego("saltoangel", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
          </Link>
        )}

{obtenerjuego("libre", "estatus") == "En Espera" ? (
          <div
            onClick={() => {
              msjsave(
                "Debes esperar la apertura de la sala, via Whatsapp",
                "warning"
              );
            }}
            className=" mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-l-yellow-500 border-t-blue-500 border-r-red-600 border-b-green-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
            <div className=" absolute mt-[-35px]">
              <img src={imgmonto} className="relative" />
              <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                {obtenerjuego("libre", "premio")} Bs
              </span>
            </div>
            <div className="flex flex-col place-content-center">
              <img src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/estrella.png?alt=media&token=f4ab7fae-a7b2-4395-af12-efaea083b28c" className=" rounded-tl-xl rounded-tr-xl" />
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("libre", "estatus") == "Empezando" ? (
                  <>{relojsaloangel}</>
                ) : (
                  <>{obtenerjuego("libre", "estatus")}</>
                )}
              </span>
            </div>
          </div>
        ) : (
          <Link to={"/libre"}>
            <div className="mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-4 border-l-yellow-500 border-t-blue-500 border-r-red-600 border-b-green-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
              <div className=" absolute mt-[-35px]">
                <img src={imgmonto} className="relative" />
                <span className=" absolute mt-[-33px] ml-[45px] text-sm font-semibold text-white">
                  {obtenerjuego("libre", "premio")} Bs
                </span>
              </div>
              <div className="flex flex-col place-content-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/estrella.png?alt=media&token=f4ab7fae-a7b2-4395-af12-efaea083b28c"
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("libre", "estatus") == "Empezando" ? (
                    <>{relojlibre}</>
                  ) : (
                    <>{obtenerjuego("libre", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
          </Link>
        )}
      </div>

      <br />
      <br />
      <br />

      <div class="h-[20px] relative bg-blue-600 flex flex-row place-content-center">
        <div className=" absolute flex flex-row mt-[-30px]">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Fbingofranja.png?alt=media&token=04376ecb-82a1-4556-a511-b3eebc7b906b"
            alt=""
            height="80px"
            width="80px"
          />
          <div className="mt-[25px] p-1 h-8 font-bold text-blue-800 text-sm bg-blue-200 rounded-lg">
            Cartones en Juego
            <div>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Fprecio.png?alt=media&token=adae5e29-ff18-4289-9ae6-c9e77385fbb1"
                height="100px"
                width="100px"
                alt=""
                className=" absolute"
              />
              <span class=" absolute inline-flex items-center  justify-center bg-gradient-to-tl from-black to-blue-800 w-10 border-2 border-red-600 h-6 ml-[50px] mt-[45px] text-[16px] font-bold rounded-lg">
                <span className="pl-2 text-white">{totalprecio}</span>
              </span>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Fbolivar.png?alt=media&token=1d677b2a-13f2-447b-ad7a-eafe72c312ad"
                height="30px"
                width="30px"
                alt=""
                className=" absolute mt-[40px] ml-[30px]"
              />
            </div>
          </div>
          <span class=" inline-flex items-center bg-slate-800 justify-center w-6 h-6 ml-[-10px] mt-[10px] text-xs font-semibold text-white  rounded-full">
            {totalcomprados + totalregalos}
          </span>

          <div className="mt-[20px] ml-2 flex flex-row">
            <svg
              class="w-[32px] h-[32px] text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                clip-rule="evenodd"
              />
            </svg>

            <span class=" inline-flex items-center bg-slate-800 justify-center w-6 h-6 ml-[-10px] mt-[-10px] text-xs font-semibold text-white  rounded-full">
              {totalcomprados}
            </span>
          </div>
          <div className="mt-[20px] ml-2 flex flex-row">
            <svg
              class="w-[32px] h-[32px] text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z" />
            </svg>

            <span class=" inline-flex items-center bg-slate-800 justify-center w-6 h-6 ml-[-10px] mt-[-10px] text-xs font-semibold text-white  rounded-full">
              {totalregalos}
            </span>
          </div>
          <Space wrap className="mt-[20px] ml-2 flex flex-row">
            <Popover
              content={content}
              title="Usuarios que Participan"
              trigger="click"
            >
              <div className="relative mt-[-26px]">
                <svg
                  class="w-[32px] h-[32px] text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class=" absolute inline-flex items-center bg-slate-800 justify-center w-6 h-6 ml-[20px] mt-[-43px] text-xs font-semibold text-white  rounded-full">
                  {totalusuarios}
                </span>
              </div>
            </Popover>
          </Space>
        </div>
      </div>

      <br />
      <br />
      <br />
      <div>
        {activar == true ? (
          <Confetti
            numberOfPieces={300}
            gravity={0.1}
            width={width}
            height={height}
          />
        ) : (
          <div></div>
        )}

        <Drawer
          title="Mis Cartones"
          placement={placement}
          closable={false}
          onClose={onClose}
          open={open}
          key={placement}
        >
          <div class="flex items-start gap-2.5">
            {documents.map((doc, index) => (
              <>
                {doc.data.estatus == "pendiente" ? (
                  <div key={doc.id}>
                    <button
                      type="button"
                      onClick={() => {
                        setDocument(doc.id);
                        setCarton(doc.data);
                        setNumcarton(index + 1);
                      }}
                      class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        class="w-6 h-6 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm2 8v-2h7v2H4Zm0 2v2h7v-2H4Zm9 2h7v-2h-7v2Zm7-4v-2h-7v2h7Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      &nbsp;
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
            {displayName == undefined ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => {
                  comprar();
                }}
                class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="currentColor"
                    d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
                  />
                </svg>
                &nbsp;Comprar
              </button>
            )}
          </div>
          <br />
          <div className="flex">
            {carton == "" ? (
              <div></div>
            ) : (
              <>
                <Mostrarcarton email={email} numeros={carton} />
                <div className="flex flex-col">
                  <div className="p-4 text-blue-900 font-bold text-xl">
                    Carton #{numcarton}
                  </div>
                  <div className="pl-4">
                    <button
                      type="button"
                      onClick={() => {
                        eliminarcarton(document);
                      }}
                      class="text-white  bg-[#ff1919f0] hover:bg-[#ff1919f0]/80 font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2 mb-2"
                    >
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Eliminar
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Drawer>

        <Drawer
          title="Mis Cartones Disponibles para Jugar"
          placement={placement}
          closable={false}
          onClose={onClose2}
          open={open2}
          key={placement}
        >
          <div class="flex items-start gap-2.5">
            {documents.map((doc, index) => (
              <>
                {doc.data.estatus !== "pendiente" ? (
                  <div key={doc.id}>
                    <button
                      type="button"
                      onClick={() => {
                        setDocument(doc.id);
                        setCarton(doc.data);
                        setNumcarton(index + 1);
                      }}
                      class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        class="w-6 h-6 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm2 8v-2h7v2H4Zm0 2v2h7v-2H4Zm9 2h7v-2h-7v2Zm7-4v-2h-7v2h7Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      &nbsp;#{index + 1}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
          <br />
          <div className="flex">
            {carton == "" ? (
              <div></div>
            ) : (
              <>
                <Mostrarcarton email={email} numeros={carton} />
                <div className="flex flex-col">
                  <div className="p-4 text-blue-900 font-bold text-xl">
                    Carton #{numcarton}
                  </div>
                </div>
              </>
            )}
          </div>
        </Drawer>

        {open == true || open2 == true ? (
          <div></div>
        ) : (
          <div className="footer bg-black flex flex-wrap items-center p-0 justify-between text-white">
            <div className=" flex flex-wrap">
              <Link to="/cartones">
                <div className="">
                  <button
                    onClick={() => {
                      principal();
                    }}
                    type="button"
                    class=" w-full  text-blue-700  hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 "
                  >
                    <svg
                      class="w-[48px] h-[48px] text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.857 3A1.857 1.857 0 0 0 3 4.857v4.286C3 10.169 3.831 11 4.857 11h4.286A1.857 1.857 0 0 0 11 9.143V4.857A1.857 1.857 0 0 0 9.143 3H4.857Zm10 0A1.857 1.857 0 0 0 13 4.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 21 9.143V4.857A1.857 1.857 0 0 0 19.143 3h-4.286Zm-10 10A1.857 1.857 0 0 0 3 14.857v4.286C3 20.169 3.831 21 4.857 21h4.286A1.857 1.857 0 0 0 11 19.143v-4.286A1.857 1.857 0 0 0 9.143 13H4.857ZM18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span class=" absolute inline-flex items-center justify-center w-32 h-6 ml-12 mt-[-2px] text-xs font-semibold text-blue-800 bg-blue-200 rounded-lg">
                      OBTEN TU CARTON
                    </span>
                  </button>
                </div>
              </Link>
            </div>

            <div className=" flex flex-wrap">
              <div className="">
                <button
                  onClick={() => {
                    showDrawer2();
                    setCarton([]);
                    principal();
                  }}
                  type="button"
                  class=" w-full  text-blue-700  hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 "
                >
                  <svg
                    class=" relative w-[48px] h-[48px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class=" absolute inline-flex items-center justify-center w-6 h-6 ml-8 mt-[-26px] text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                    {totalc}
                  </span>
                </button>
              </div>
              <div className="">
                <button
                  type="button"
                  onClick={() => {
                    showDrawer();
                    setCarton([]);
                    comenta();
                  }}
                  class="relative  w-full  font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2"
                >
                  <svg
                    class="w-[48px] h-[48px] text-white dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class=" absolute inline-flex items-center justify-center w-6 h-6 ml-8 mt-[-26px] text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                    {totalp}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer theme="dark" />
      {salir ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <span className="mt-6 pl-2">Salir</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 font-bold text-center">
                        Deseas Cerrar Sesi&oacute;n?
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    &nbsp;Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleLogout();
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-white bg-[#a51b1b] hover:bg-[#d12121]/90 focus:ring-4 focus:outline-none focus:ring-[#d12121]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                    &nbsp;Salir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Home;

import FormsFirebase from "./page/formFirebase";
import Adminis from "./page/adminis";
import { AuthProvider } from "./context/AuthContext";
import { HashRouter, Route, Routes, Link } from "react-router-dom";
import Home from "./page/home";
import Maracaibo from "./page/maracaibo";
import Admingame from "./page/admingame";
import Caracas from "./page/caracas";
import Merida from "./page/merida";
import Saltoangel from "./page/saltoangel";
import Cartonesfijos from "./page/cartonesfijos";
import Asignarcarton from "./page/asignarcarton";
import Juego from "./page/juego";
import Lista from "./page/lista";
import Libre from "./page/libre";
import Adminlibre from "./page/adminlibre";

//const socket = io("/");

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cartones" element={<FormsFirebase />} />
            <Route path="/cartonesfijos" element={<Cartonesfijos />} />
            <Route path="/asignarcarton" element={<Asignarcarton />} />
            <Route path="/lista" element={<Lista />} />
            <Route path="/adminis" element={<Adminis />} />
            <Route path="/admingame" element={<Admingame />} />
            <Route path="/adminlibre" element={<Adminlibre />} />
            <Route path="/juego" element={<Juego />} />
            <Route path="/marc" element={<Maracaibo />} />
            <Route path="/carc" element={<Caracas />} />
            <Route path="/merc" element={<Merida />} />
            <Route path="/salc" element={<Saltoangel />} />
            <Route path="/libre" element={<Libre />} />
          </Routes>
        </HashRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
